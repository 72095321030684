import React from "react"
import { LocalizationContainer } from "./wiczlinoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import wiczlino2LocImage from "../../../images/z-16/ogw2/komunikacja2.jpg"
import { MobileButton } from "../../shared/button/button"

const Localization = ({ estate, intl }) => (
  <LocalizationContainer className="localization">
    <Container className="localization-title">
      <Row>
        <Col xs="12" className="no-padding">
          <h1>
            {intl.formatMessage({ id: "offer.wiczlino.localization.title" })}
          </h1>
        </Col>
      </Row>
    </Container>
    <Container style={{ margin: "auto", padding: "30px 10px" }}>
      <Row className="google-map-row">
        <Col xs="12" className="no-padding">
          <img
            className={"loc-map"}
            src={wiczlino2LocImage}
            alt={"OGW2 Lokalizacja"}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className="show-more"
          xs="12"
          style={{
            padding: "0px 0px 40px 0px",
            "max-width": "300px",
            margin: "auto",
          }}
        >
          <a target="_blank" href="https://goo.gl/maps/Tq4LbHBaHtbVsSQP9">
            <MobileButton
              innerText={intl.formatMessage({
                id: "offer.janowo.localization.info.google_button.text",
              })}
              bg="#94C835"
              color="white"
              border="#94C835"
              arrow={false}
            />
          </a>
        </Col>
      </Row>
    </Container>
    {/* <Container className="localization-content">
      <Row>
        <Col xs="12" lg="3" className="no-padding places-title">
          <p>
            <span>Świetna lokalizacja</span> umożliwia szybkie poruszanie się do
            miejsc, które lubisz albo potrzebujesz.
          </p>
        </Col>
        <Col xs="2" className="places-offset" />
        <Col xs="12" lg="7" className="no-padding places-info">
          <div>
            <span>4min</span> do Biedronki, apteki i siłowni
          </div>
          <div>
            <span>5min</span> samochodem do SKM Rumia Janowo
          </div>
          <div>
            <span>7min</span> do szpitala
          </div>
        </Col>
      </Row>
    </Container> */}
    {/* <Container fluid className="localization-vector no-padding">
      <Row>
        <Col xs="12" className="no-padding">
          <div className="vector-background"></div>
        </Col>
      </Row>
    </Container> */}
  </LocalizationContainer>
)

export default injectIntl(Localization)
